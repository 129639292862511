@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root{
  --background:#222222;
  --primary:#045757;
  --secondary:#044343;
  --light:#E4E4E4;
}


body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  box-sizing: border-box;
  /* background-color: #0f0f0f; */
  background-color: #171717;
}

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a{
  text-decoration: none;
}

canvas {
  display: block;
  margin: auto;
  position: absolute;
  left: -120px;
  width: 400px !important;
  height: 180px !important;
  background-color: var(--background);
}


.text-page{
  width: 100%;
  max-width: 800px;
  margin: 50px auto;
  color: white;
}

.text-page *{
  margin-bottom: 20px;
  list-style: none;
}

.text-page h1{
  text-align: center;
  margin-bottom: 50px;
}

.text-page-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}