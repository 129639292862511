
.settings-menu{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  background-color: #2f2f2f;
  border-radius: 10px;
  padding: 10px;
}

.settings-menu button{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: unset;
  border: none;
  padding: 10px;
  gap: 10px;
  width: 200px;
  border-radius: 6px;
  cursor: pointer;
}

.settings-menu button:hover{
  background-color: #424242;
}



.settings-menu img{
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.settings-menu span{
  color: white;
}

.line{
  width: 100%;
  height: 1px;
  background-color: #ffffff1a;
}