.auth-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #0f0f0f;
}

.auth{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  /* background-color: #191919; */
  background-color: #212121;
  padding: 30px 20px;
  box-shadow: rgb(255 249 249 / 16%) 0px -1px 0px;
  gap: 20px;
}

.auth .header{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}

.auth .header img{
  width: 130px;
  margin-bottom: 20px;
}

.auth .header h2{
  margin: 5px 0;
  color: var(--light);
  font-size: 21px;
}

.auth .header span{
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
}

.auth .header span a{
  color: white;
}

.auth .body{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
}

.auth .body input{
  width: 320px;
  background-color: #0f0f0f;
  border: none;
  border-radius: 8px;
  padding: 10px 13px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin: 0;
  color: white;
  outline: none !important;
}

.auth .body button{
  width: 100%;
  padding: 10px 13px;
  background-color: #0074dd;
  border: none;
  border-radius: 8px;
  box-shadow: #0072dd69 0px 1px 4px;
  margin-top: 5px;
  color: white;
  cursor: pointer;
}


.or{
  width: 100%;
  display: block;
  position: relative;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 11px;
  font-weight: 500;
}

.or::before{
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  content: '';
  width: 44%;
  height: 1px;
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.1);
  left: 0;
}

.or::after{
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  content: '';
  width: 44%;
  height: 1px;
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.1);
  right: 0;
}

@media only screen and (max-width: 600px) {
  
  .auth{
    padding: 15px 10px;
  }
  .auth .body input{
    width: 270px;
  }
  

}