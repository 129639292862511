.has-call-home{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.has-call-home .call-list{
  padding: 10px;
  display: flex;
  justify-self: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-color: #2f2f2f;
  gap: 10px;
  border-radius: 5px;
}

.call-list-box{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  gap: 15px;
  color: white;
  font-size: 14px;
  border-radius: 5px;
}

.call-list-box:hover{
  background-color: #424242;
}

.call-list-box img{
  width: 30px;
}

.call-list-box b{
  display: block;
  margin-bottom: 3px;
}

.has-call-home .right{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}