.loading{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: #171717;
  display: flex;
  justify-content: center;
  align-items: center;
}