.home{
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.home .body{
  border-top: 4px solid #212121;
  /* background-color: #212121; */
  padding: 10px;
  max-width: 1250px;
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}