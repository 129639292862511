.call-detail{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  color: #E0E0E0;
  position: relative;
}

.call-detail a{
  background: unset;
  border: unset;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 99;
  cursor: pointer;
}

.call-detail a img{
  width: 45px;
}

.call-detail b{
  color: var(--light);
  margin-bottom: 30px;
  display: block;
}

.time{
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  gap: 10px;
  margin: 10px 0;
}

.time span{
  font-size: 15px;
}

.time img{
  width: 24px;
}

.summary{
  margin: 20px 0;
}

.summary span{
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
}

.summary p{
  font-size: 15px;
}

.conversation{
}

.conversation>span{
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
}

.chat{
  background-color: rgba(255, 255, 255, .03);
  padding: 10px;
  border-radius: 5px;
  max-height: 500px;
  overflow: auto;
}

.chat-box{
  padding: 5px;
  background-color: rgba(255, 255, 255, .1);
  border-radius: 5px;
  margin-bottom: 10px;
  width: fit-content;
  max-width: 70%;
  display: block;
}

.user-chat-box{
  margin-left: auto;

}