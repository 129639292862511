.main-header{
  display: flex;
  width: 100%;
  max-width: 1250px;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px 5px 20px;
}

.main-header .logo img{
  width: 130px;
}

.settings-container{
  position: relative;
}

.settings-container .profile{
  width: 40px;
  height: 40px;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.settings-container .profile img{
  width: 100%;
  object-fit: contain;
}

.settings{
  position: absolute;
  right: 0;
  top: 120%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  background-color: #2f2f2f;
  border-radius: 10px;
  padding: 10px;
}

.settings button{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: unset;
  border: none;
  padding: 10px;
  gap: 10px;
  width: 200px;
  border-radius: 6px;
  cursor: pointer;
}

.settings button:hover{
  background-color: #424242;
}



.settings img{
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.settings span{
  color: white;
}

.line{
  width: 100%;
  height: 1px;
  background-color: #ffffff1a;
}