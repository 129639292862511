.account-home{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.account-inner{
  flex: 1;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
}

.account-inner b{
  color: var(--light);
  margin-bottom: 30px;
  display: block;
}

.account-inner .box{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: unset;
  border: none;
  padding: 10px;
  gap: 10px;
  width: 200px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, .1);
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
}

.account-inner .box:hover{
  background-color: #424242;
}



.account-inner img{
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.account-inner span{
  color: white;
}

.line{
  width: 100%;
  height: 1px;
  background-color: #ffffff1a;
}

.profile-img{
  width: 150px;
  height: 150px;
  border-radius: 100%;overflow: hidden;
  margin: 30px 0;
}

.profile-img img{
  width: 100%;
  height: 100%;
}